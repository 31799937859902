import ThreeGlobe from "three-globe";
import { WebGLRenderer, Scene } from "three";
import {
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  Color,
  Fog,
  PointLight,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

//import patientAcquisitions from "./static/data/patient-acquisitions.json";
const patientAcquisitions = await fetch('/data/patient-acquisitions.json').then(response => response.json())
                        .catch(error => {
                            console.error('Failed to load countries data:', error);
                            return [];  // Return an empty array on error
                        });

// Load country data asynchronously
//import countries from "./static/data/globe-data-min.json";
//import countries from "./static/data/ne_110m_admin_0_countries.json";
const countries = await fetch('/data/globe-data-min.json').then(response => response.json())
                        .catch(error => {
                            console.error('Failed to load countries data:', error);
                            return [];  // Return an empty array on error
                        });

//import providerLoc from "./static/data/provider-locations.json";
const providerLoc = await fetch('/data/provider-locations.json').then(response => response.json())
                        .catch(error => {
                            console.error('Failed to load provider location data:', error);
                            return [];  // Return an empty array on error
                        });

const trialdetails = await fetch('/data/trial-details.json').then(response => response.json())
                        .catch(error => {
                            console.error('Failed to load trial details data:', error);
                            return [];  // Return an empty array on error
                        });

var renderer, camera, scene, controls;
let mouseX = 0;
let mouseY = 0;
let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;
var Globe;
const colorInterpolator = t => `rgba(100,250,50,${1-t})`;


init();
initGlobe();
onWindowResize();
animate();


async function loadCountries() {
  try {
    const response = await fetch('/static/data/globe-data-min.json');
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Could not load countries:', error);
  }
}

// SECTION Initializing core ThreeJS elements
function init() {
 
  // Initialize renderer
  renderer = new WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  const div = document.getElementById('patient-globe');
  // Get the dimensions of the 'patient-globe' div
  const width = div.clientWidth;
  const height = div.clientHeight;
  renderer.setSize(width, height);
  // Ensure the canvas covers the entire div
  renderer.domElement.style.position = 'absolute';
  renderer.domElement.style.top = 0;
  renderer.domElement.style.left = 0;
  renderer.domElement.style.width = '100%';
  renderer.domElement.style.height = '100%';
  div.appendChild(renderer.domElement);
  //document.body.appendChild(renderer.domElement);

  // Initialize scene, light
  scene = new Scene();
  scene.add(new AmbientLight(0xbbbbbb, 0.3));
  scene.background = new Color(0x040d21);

  // Initialize camera, light
  camera = new PerspectiveCamera();
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  var dLight = new DirectionalLight(0xffffff, 0.8);
  dLight.position.set(-800, 2000, 400);
  camera.add(dLight);

  var dLight1 = new DirectionalLight(0x7982f6, 1);
  dLight1.position.set(-200, 500, 200);
  camera.add(dLight1);

  var dLight2 = new PointLight(0x8566cc, 0.5);
  dLight2.position.set(-200, 500, 200);
  camera.add(dLight2);

  camera.position.z = 200;
  camera.position.x = 350;
  camera.position.y = 100;

  scene.add(camera);

  // Additional effects
  scene.fog = new Fog(0x535ef3, 400, 2000);

  // Helpers
  // const axesHelper = new AxesHelper(800);
  // scene.add(axesHelper);
  // var helper = new DirectionalLightHelper(dLight);
  // scene.add(helper);
  // var helperCamera = new CameraHelper(dLight.shadow.camera);
  // scene.add(helperCamera);

  // Initialize controls
  controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dynamicDampingFactor = 0.01;
  controls.enablePan = false;
  controls.minDistance = 200;
  controls.maxDistance = 500;
  controls.rotateSpeed = 0.8;
  controls.zoomSpeed = 1;
  controls.autoRotate = false;

  controls.minPolarAngle = Math.PI / 3.5;
  controls.maxPolarAngle = Math.PI - Math.PI / 3;

  //diable controls
  controls.enableZoom = false;
  controls.enablePan = false;
  controls.enableRotate = false;

  window.addEventListener("resize", onWindowResize, false);
  //document.addEventListener("mousemove", onMouseMove);
}

// SECTION Globe
function initGlobe() {
    
    // Gen random data
    const N = 30;
    const gData = [...Array(N).keys()].map(() => ({
      lat: (Math.random() - 0.5) * 180,
      lng: (Math.random() - 0.5) * 360,
      maxR: 4,
      propagationSpeed: 2.5,
      repeatPeriod: 800
    }));
  // Initialize the Globe
  Globe = new ThreeGlobe({
    waitForGlobeReady: true,
    animateIn: true,
  })    
    .hexPolygonsData(countries.features)
    .hexPolygonResolution(3)
    .hexPolygonMargin(0.7)
    .showAtmosphere(true)
    .atmosphereColor("#3a228a")
    .atmosphereAltitude(0.25)
    .hexPolygonColor((e) => { return "rgba(0,255,255, 1)"});
    // .hexPolygonColor((e) => {
    //   if (
    //     ["KGZ", "KOR", "THA", "RUS", "UZB", "IDN", "KAZ", "MYS"].includes(
    //       e.properties.ISO_A3
    //     )
    //   ) {
    //     return "rgba(255,255,255, 1)";
    //   } else return "rgba(255,255,255, 0.7)";
    // });

  // NOTE Arc animations are followed after the globe enters the scene
  setTimeout(() => {
    Globe.arcsData(patientAcquisitions.flights)
      .arcColor((e) => {
        return e.status = "#f95006";
        //return e.status ? "#9cff00" : "#FF4000";
      })
      .arcAltitude((e) => {
        return e.arcAlt = (Math.random() * (0.01 + 0.5));
      })
      .arcStroke((e) => {
        return e.status ? 0.5 : 0.3;
      })
      .arcDashLength(0.9)
      .arcDashGap(4)
      .arcDashAnimateTime(1000)
      .arcsTransitionDuration(1000)
      .arcDashInitialGap((e) => e.order * 1)
      
      //All labels
      .labelsData(providerLoc.airports)
      .labelColor(() => "#ffffffff")
      .labelDotOrientation("top")
      .labelDotRadius(0.3)
      .labelSize(1.2)
      .labelText("city")
      .labelResolution(6)
      .labelAltitude(0.01)
      
      // .pointsData(providerLoc.airports)
      // .pointColor(() => "#ffffff")
      // .pointsMerge(true)
      // .pointAltitude(0.07)
      // .pointRadius(0.05);

    Globe.ringsData(trialdetails.sites)
      .ringColor(() => colorInterpolator)
      .ringMaxRadius('maxR')
      .ringPropagationSpeed('propagationSpeed')
      .ringRepeatPeriod('repeatPeriod');

    //Globe.rotateY(Math.PI * 1.767);
    //Globe.rotateZ(-Math.PI / 9);
    }, 1000);

 

  const globeMaterial = Globe.globeMaterial();
  globeMaterial.color = new Color(0x3a228a);
  globeMaterial.emissive = new Color(0x220038);
  globeMaterial.emissiveIntensity = 0.1;
  globeMaterial.shininess = 0.7;

  // NOTE Cool stuff
  // globeMaterial.wireframe = true;
  scene.add(Globe);
}

function onMouseMove(event) {
  mouseX = event.clientX - windowHalfX;
  mouseY = event.clientY - windowHalfY;
  // console.log("x: " + mouseX + " y: " + mouseY);
}

function onWindowResize() {
  const div = document.getElementById('patient-globe');
  const width = div.clientWidth;
  const height = div.clientHeight;
  camera.aspect = div.clientWidth / div.clientHeight;
  camera.updateProjectionMatrix();
  windowHalfX = div.clientWidth / 1.5;
  windowHalfY = div.clientHeight / 1.5;
  renderer.setSize(width, height);
}

function animate() {
  // camera.position.x +=
  //   Math.abs(mouseX) <= windowHalfX / 2
  //     ? (mouseX / 2 - camera.position.x) * 0.005
  //     : 0;
  // camera.position.y += (-mouseY / 2 - camera.position.y) * 0.005;
  //camera.lookAt(scene.position);
  //controls.update();

  //auto rotate the globe
  //Globe.rotation.y += 0.001;
  renderer.render(scene, camera);
  requestAnimationFrame(animate);
}
